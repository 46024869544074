@import "../styles/mixins.scss";

.LoginColumns {
  display: flex;
  flex-direction: column;
  padding: 0 rem(16);

  @include desktop {
    flex-direction: row;
    padding: 0;
  }

  h3 {
    @include font-bold;
    @include font-paragraph-large;
  }

  .LeftCol {
    order: 2;

    @include desktop {
      width: 56%;
      padding-right: rem(190);
      margin-right: rem(67);
      border-right: 1px solid var(--color-grey);
      order: 1;
    }

    p {
      @include font-book;
      @include font-paragraph-large;
      margin: rem(12) 0;
      &:nth-child(2) {
        margin: rem(47) 0;
      }
    }
  }

  .RightCol {
    order: 1;
    margin-top: rem(55);
    margin-bottom: rem(96);

    @include desktop {
      padding-right: 4rem;
      margin-top: 0;
      order: 2;
    }

    button {
      width: 100%;
      margin: rem(12) 0;
      @include desktop {
        width: rem(324);
      }
    }
  }
}

.AppImage {
  position: relative;

  width: rem(162);
  height: rem(329);

  @include desktop {
    width: rem(177);
    height: rem(361);
  }
}

.StoreButton {
  position: relative;
  width: rem(149);
  height: rem(44);
}

.AppPreviews {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: rem(10);
  margin-top: rem(90);
  margin-bottom: rem(20);

  @include desktop {
    gap: rem(18);
  }
}

.AppPreview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AppImage {
  margin-bottom: rem(35);
}
